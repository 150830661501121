<template>
  <div class="wizard-body mx-auto bg-white shadow-sm">

    <AlreadyTakenPopup
      ref="alreadyTakenPopup"
      :email_taken="email_taken"
      :personnr_taken="personnr_taken"
      :rate_limit="rate_limit"
      @toLoginClicked="toLoginClicked"
      @cancel="to_login_cancel_clicked"
    />

    <b-card v-if="created && company.company_settings.reg_approval">
      <b-card :title="$t('PUBLIC.REGISTRATION.MEMBERSHIP_PENDING')" hide-footer class="mb-2 mt-2 ">
        <b-alert variant="success" show
          >{{$t('PUBLIC.REGISTRATION.MEMBERSHIP_PENDING_INFO')}}</b-alert
        >
      </b-card>
    </b-card>
    <div v-else>
      <b-card v-if="created" :title="$t('PUBLIC.REGISTRATION.MEMBERSHIP_CREATED')" hide-footer class="mb-2 mt-2 ">
        <b-alert variant="success" show
          >{{$t('PUBLIC.REGISTRATION.MEMBERSHIP_CREATED_INFO')}}</b-alert
        >
      </b-card>

    </div>

    <b-card v-if="error_expected_action" :title="$t('PUBLIC.REGISTRATION.EXPECTED_ACTION_ERROR_HEADER')" hide-footer class="mb-2 mt-2 bg-white">
      <b-alert variant="danger" show>{{$t('PUBLIC.REGISTRATION.EXPECTED_ACTION_ERROR')}}</b-alert>
    </b-card>


    <b-card v-if="error_loading" :title="$t('PUBLIC.REGISTRATION.ERROR_OCCURED')" hide-footer class="mb-2 mt-2 bg-white">
      <b-alert variant="danger" show>{{$t('PUBLIC.REGISTRATION.UNABLE_LOAD')}}</b-alert>
    </b-card>


    <b-card v-if="error" :title="$t('PUBLIC.REGISTRATION.ERROR_OCCURED')" hide-footer class="mb-2 mt-2 bg-white">
      <b-alert variant="danger" show>{{$t('PUBLIC.REGISTRATION.UNABLE_CREATE_MEMBERSHIP')}}</b-alert>
    </b-card>

    <!-- End: Member Type-->
    <b-card v-if="!created && !error_loading" title="" class="wizard-body mb-5 pt-5 bg-white" hide-footer>
      <!--begin: Wizard-->

      <b-card v-if="pages === null || pages.length === 0">
        <div class="d-flex justify-content-center mb-3">
          <memlist-spinner />
        </div>
      </b-card>

      <!--begin: Wizard Body-->
      <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="col-xl-12">
          <!--begin: Wizard Form-->
          <form class="form" id="kt_form" @submit.prevent="onSubmit">
            <template v-for="(page, i) in pages">

              <!-- Begin: Personnr -->
              <div class="fade-in-up-enter-to" :key="i + 2000" v-if="page.type === 'personnr'">
                <PersonnrPage
                  v-show="renderPage.id === page.id"
                  ref="ref_page_personnr"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  @isAlreadyRegistered="personnrIsAlreadyRegistered"
                  :requirements="requirements"
                  :page_data.sync="page_data"
                  :page="page"
                  :isRenew="false"
                  :allow_duplicates="company.company_settings.allow_duplicates"
                  @ageUpdated="ageUpdated"
                  @doSparLookup="doSparLookup"
                  @on_ladok_data="on_ladok_data"
                />
              </div>
              <!-- End: Personnr -->

              <!-- Begin: Details -->
              <div class="fade-in-up-enter-to" :key="i + 3000" v-else-if="page.type === 'details'">
                <DetailsPage
                  v-show="renderPage.id === page.id"
                  ref="ref_page_details"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  @isAlreadyRegistered="emailIsAlreadyRegistered"
                  :requirements="requirements"
                  :page_data.sync="page_data"
                  :spar="spar"
                  :page="page"
                  :personnr="postForm.personnr.personnr"
                  :regions="regions"
                />
              </div>

              <!-- End: Details -->

              <!-- Begin: Student -->
              <div class="fade-in-up-enter-to" :key="i + 4000" v-else-if="page.type === 'student'">
                <StudentPage
                  v-show="renderPage.id === page.id"
                  ref="ref_page_student"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :pageData.sync="page_data"
                  :page="page"
                />
              </div>

              <!-- End: Student -->

              <!-- Begin: Register Family -->
              <div class="fade-in-up-enter-to" :key="i + 5000" v-else-if="page.type === 'reg_family'">
                <RegisterFamily
                  v-show="renderPage.id === page.id"
                  ref="ref_page_reg_family"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :company_id="company_id"
                  :page_data.sync="page_data"
                  :page="page"
                  :me="me"
                  :regions="regions"
                />
              </div>

              <!-- End: Register Family -->

              <!-- Begin: Properties -->
              <div class="fade-in-up-enter-to" :key="i + 6000" v-else-if="page.type === 'properties'">
                <PropertyPage
                  v-show="renderPage.id === page.id"
                  ref="ref_page_properties"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :company_id="company_id"
                  :page="page"
                  :page_data.sync="page_data"
                />
              </div>
              <!-- End: Properties -->

              <!-- Begin: Parents -->
              <div
                class="pb-5 fade-in-up-enter-to"
                :key="i + 7000"
                v-else-if="page.type === 'parents'"
              >
                <ParentsPage
                  v-show="renderPage.id === page.id"
                  ref="ref_page_parents"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :age="age"
                  :page_data.sync="page_data"
                  :ageMaxParent="company.registration.age_max_parent"
                  :page="page"
                  :regions="regions"
                />
              </div>
              <!-- End: Parents -->

              <!-- Begin: Shop -->
              <div class="fade-in-up-enter-to" :key="i + 8000" v-else-if="page.type === 'shop'">
                <ShopMembershipSelect
                  v-show="renderPage.id === page.id"
                  ref="ref_page_shop"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :member_renew_id="null"
                  :memberships="memberships"
                  :extras="extras"
                  :page="page"
                  :page_data.sync="page_data"
                  :age="age"
                  :company_id="company_id"
                  :company_ids="company_ids"
                  @membershipUpdated="membershipUpdated"
                />
              </div>
              <!-- End: Shop -->

              <!-- Begin: registration V2 -->
              <div class="fade-in-up-enter-to" :key="i + 9000" v-else-if="page.type === 'company_select_v2'">
                <CompanySelectRegistrationV2
                  v-show="renderPage.id === page.id"
                  ref="ref_page_company_select_v2"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :company_id="company_id"
                  :page_data.sync="page_data"
                  :page="page"
                  :age="age"
                  :region_code="region_code"
                  :commune_code="commune_code"
                  @selected="companies_selected"
                />
              </div>
              <!-- End: registration V2 -->


              <!-- Begin: Final confirm step -->
              <div class="fade-in-up-enter-to" :key="i + 10000" v-else-if="page.type === 'confirm'">
                <ConfirmRegisterPage
                  v-show="renderPage.id === page.id"
                  ref="ref_page_confirm"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :page="page"
                  :regdata="regdata"
                  :company="company"
                  :page_data.sync="page_data"
                  @isAllPolicyChecked="isAllPolicyChecked"
                />
              </div>
              <!-- End: Final confirm step -->
            </template>

            <!--begin: Wizard Actions -->
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2" v-show="currentPage !== 0">
                <button
                  class="reg-button btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                  @click.prevent="previousPage"
                  type="button"
                >
                {{$t('PAGES.REGISTRATION.PREV_STEP')}}
                </button>
              </div>
              <div v-if="!lastPage">
                <button
                  class="reg-button btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  type="submit"
                  :disabled="!showNextPage"
                >
                {{$t('PAGES.REGISTRATION.NEXT_STEP')}}
                </button>
              </div>

              <div v-if="lastPage && showNextPage">
                <button
                  class="reg-button btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  type="submit"
                  :disabled="disable_create_button"
                >
                  {{ this.page_data.data.mustpay ? $t('PAGES.REGISTRATION.PROCEED_TO_PAYMENT') : $t('PAGES.REGISTRATION.CREATE_ACCOUNT') }}
                </button>
              </div>
            </div>
            <!--end: Wizard Actions -->
          </form>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>


.wizard-body {
  max-width: 1000px;
  width: 100%;
}

:deep .card-body {
  @media (max-width: 1024px) {
    padding: 1rem 0rem !important;
  }
}

</style>

<script>
import i18nService from "@/core/services/i18n.service.js";
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import messageParent from '@/core/services/messageParent';
import axios from 'axios';

import ShopMembershipSelect from '@/view/pages/members/register/registerwizard/ShopMembershipSelect.vue';
import ConfirmRegisterPage from '@/view/pages/members/register/registerwizard/ConfirmRegisterPage.vue';
import PropertyPage from '@/view/pages/members/register/registerwizard/PropertyPage.vue';
import PersonnrPage from '@/view/pages/members/register/registerwizard/PersonnrPage.vue';
import AlreadyTakenPopup from '@/view/pages/members/register/registerwizard/AlreadyTakenPopup.vue';
import ParentsPage from '@/view/pages/members/register/registerwizard/ParentsPage.vue';
import DetailsPage from '@/view/pages/members/register/registerwizard/DetailsPage.vue';

import RegisterFamily from './registerwizard/RegisterFamily';
import StudentPage from '@/view/pages/members/register/registerwizard/StudentPage.vue';
import CompanySelectRegistrationV2 from '@/view/pages/members/register/registerwizard/company_select_v2/CompanySelectRegistrationV2.vue';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { set_session_customer_id } from '@/core/services/member.service.js';

import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import { isPlugin } from "@/core/services/messageParent";


export default {
  name: 'RegisterWizard',
  mixins: [ toasts ],
  components: {
    ShopMembershipSelect,
    ConfirmRegisterPage,
    PropertyPage,
    PersonnrPage,
    ParentsPage,
    DetailsPage,

    RegisterFamily,
    AlreadyTakenPopup,
    StudentPage,
    CompanySelectRegistrationV2
  },
  mounted: function() {
    if (this.$route.query.lang) {
      const new_lang = this.$route.query.lang;

      if (new_lang.length === 2) {
        if (localStorage.getItem("language") !== new_lang) {
          localStorage.setItem("language", new_lang);
          i18nService.locale = new_lang;
          location.reload();
        }

      }

    }

    
    messageParent(JSON.stringify({ message: 'this is a message from the child iframe' }));
    this.loadData();
  },
  data() {
    return {

      company_ids: [],

      error_expected_action: null,

      error_loading: false,

      test_page: {
        html: 'test'
      },

      company_id: null,
      disable_create_button: false,
      rate_limit: false,
      email_taken: false,
      personnr_taken: false,
      isNextButtonOK: false,
      pageValidations: {},
      member_renew_id: null,
      me: null,
      family_page: null,
      getPersonnr: null,
      requirements: {},
      spar: null,
      registration: null,
      form: {},
      memberships: [],
      extras: [],
      company: {},
      regdata: {},
      accept_terms: false,
      age: 0,
      groups: [],
      region_code: '',
      commune_code: '',
      regions: [],
      communes: [],
      regionOptions: [],
      communeOptions: [],
      showNext: false,
      currentPage: 0,
      currentPageType: '',
      wizard: null,
      order: {},
      pages: [],
      ref_page_confirm: {
        settings: {
          terms: []
        }
      },
      page_data: {
        data: {}
      },

      created: false,
      error: false,
      show: '',
      show_list: [],
      personNr: {},
      renderPage: null,
      postForm: { // the data from all pages that is POSTed to server
        area_alloc: {
          company_ids: []
        },
        shop: {
          shop_items: [],
          cart: [],
          total_price: 0,
          total_vat: 0
        },
        details: {
          firstname: null, lastname: null, address: null, co: null, commune_code: null, region_code: null, dob: null, email: null, email2: null, invoice_address: null, invoice_firstname: null, invoice_lastname: null, invoice_post: null, country: 'NONE', invoice_country: 'NONE', invoice_zipcode: null, landline: null, password: null, password2: null, phone: null, post: null, zipcode: null, company_name: null
        },
        personnr: {
          personnr: null,
          skip: false
        },
        properties: [],
        family: [],
        parents: {}
      },
    };
  },
  watch: {
    age(newVal, oldVal) {
      // find min age we allow without parent
      if (newVal > this.registration.age_max_parent) {
        this.pages = this.pages.filter(p => p.type !== 'parents');
      }
    },
    commune_code(newVal, oldVal) {
      if (!newVal) {
        return;
      }
      if (this.region_code in this.regions && newVal in this.regions[this.region_code].communes) {
        this.form.commune = this.regions[this.region_code].communes[newVal];
      }
    },
    region_code(newVal, oldVal) {
      if (!newVal) {
        return;
      }

      if (newVal in this.regions) {
        this.form.region = this.regions[newVal].region;
      }
    },
    showNext(newVal, oldVal) {}
  },
  computed: {
    showNextPage() {
      if (!this.renderPage || !this.pageValidations) {
        return false;
      }

      return this.isNextButtonOK;
    },
    pageArray() {
      return Object.values(this.page_data)
        .filter(
          a => a.id !== undefined && a.id !== null && a.index !== undefined && a.index !== null
        )
        .sort((a, b) => a.index - b.index);
    },
    lastPage() {
      if (!this.renderPage || !this.pageArray) return false;
      return this.renderPage.id === this.pageArray[this.pageArray.length - 1].id;
    },
    nextIndex() {
      let next = this.currentPage + 1;

      for (let i = next; i < this.pageArray.length; i++) {
        if (this.pageArray[i].skip) {
          next = next + 1;
        } else {
          break;
        }
      }

      if (next >= this.pageArray.length) {
        return -1;
      }

      return next;
    },
    previousIndex() {
      let previous = this.currentPage - 1;

      for (let i = previous; i >= 0; i--) {
        if (this.pageArray[i].skip) {
          previous = previous - 1;
        } else {
          break;
        }
      }

      if (previous < 0) {
        return -1;
      }

      return previous;
    },
    familyPage() {
      return this.pageArray.find(p => p.type === 'reg_family');
    },
    ref_page_parents() {
      return this.pageArray.find(p => p.type === 'parents');
    },
    ref_page_area_alloc() {
      return this.pageArray.find(p => p.type === 'area_alloc');
    }
  },
  methods: {
    /// companies has been selected from Company Select V2
    companies_selected(company_ids) {

      console.log('companies_selected', company_ids);

      this.postForm.area_alloc.company_ids = company_ids;

      this.postForm = { ...this.postForm };

      this.company_ids = company_ids;

      this.company_ids = [ ...this.company_ids ];
    },


    emailIsAlreadyRegistered(email) {
      this.showAlreadyRegistered(false, true);
    },
    personnrIsAlreadyRegistered(personnr) {
      this.showAlreadyRegistered(true, false);
    },
    calcNextButtonIsOKForCurrentPage() {

      const ref_name = `ref_page_${this.currentPageType}`;
      const ref = this.$refs[ref_name][0];

      if (!ref) {
        console.error('current page has undefiend ref');

        return;
      }

      this.isNextButtonOK = ref.isNextButtonOK();

    },

    setPageData(pageType, data) {
      const ref_name = `ref_page_${pageType}`;
      const ref = this.$refs[ref_name][0];

      if (!ref) {
        ref.setData(data);
      }

    },

    getPageData(pageType) {

      const ref_name = `ref_page_${pageType}`;
      const ref = this.$refs[ref_name][0];

      if (!ref) {
        console.error('current page has undefined ref', pageType);

        return null;
      }

      return ref.getData();
    },
    getAgeFromDob(dob) {
      const personnr = formatPersonnr(dob);

      if (personnr.success) {
        return dayjs().diff(
          personnr.year + '-' + personnr.month + '-' + personnr.day,
          'years'
        );
      }

      return 18;
    },

    wizardPageWasUpdated(pageType, data, isNextButtonOK) {

      const pd = pageType === 'details' ? data : this.getPageData(pageType);

      if (pd === null || pd === undefined) {
        console.error('wizardPageWasUpdated pd is null', pageType, pd);
        return;
      }

      switch (pageType) {
        case 'student': {
          this.postForm.student = pd;

          this.setPageData('details', pd.member);

          break;
        }
        case 'shop': {
          this.postForm.shop = pd;
          this.postForm.campaign_code = pd.campaign_code;
          break;
        }
        case 'company_select_v2': {
          this.postForm.area_alloc = pd;

          this.postForm = { ...this.postForm };

          break;
        }
        case 'area_alloc': {
          this.postForm.area_alloc = pd;

          this.postForm = { ...this.postForm };

          break;
        }
        case 'details': {

          for (const k in pd) {
            if (k && pd[k] !== null && pd[k] !== undefined && pd[k] !== '') {
              this.postForm.details[k] = pd[k];
            }
          }

          //this.postForm.details = pd;

          if (pd.dob !== null && pd.dob !== undefined && pd.dob !== '') {
            const age = this.getAgeFromDob(pd.dob);
            
            this.ageUpdated(age);
            this.age = age;
          }

          break;
        }
        case 'personnr': {
          this.postForm.personnr = pd;

          if (pd.skip) {
            this.isNextButtonOK = true;
          }
          else {
            this.isNextButtonOK = isNextButtonOK;
          }

          break;
        }
        case 'reg_family': {
          this.postForm.family = pd;

          break;
        }
        case 'parents': {
          this.postForm.parents = pd;

          break;
        }

        case 'properties': {
          this.postForm.properties = pd;

          break;
        }

      }

      if (pageType === this.currentPageType) {
        this.isNextButtonOK = isNextButtonOK;
      }
    },

    async onSubmit() {

      if (this.lastPage) {
        this.createAccount();
      } else {
        if (this.currentPageType == 'details') {
          const ref_name = `ref_page_${this.currentPageType}`;
          const ref = this.$refs[ref_name][0];
          const valid = await ref.validate();
          
          if (!valid) { return; }
        }
        this.nextPage();
      }
    },

    continueNextPageAfterCheckIdentification() {

      if (this.nextIndex >= 0) {
        this.renderPage = this.pageArray[this.nextIndex];
        this.currentPage = this.nextIndex;
      }

      this.currentPageType = this.renderPage.type;

      window.scrollTo(0, 0);

      this.calcNextButtonIsOKForCurrentPage();

      setTimeout(function() {
        messageParent(JSON.stringify({ height: document.body.scrollHeight }));
      }, 300);
    },
    showAlreadyRegistered(personnr_taken, email_taken) {

      if (this.company.company_settings.allow_duplicates) {
        return;
      }

      this.personnr_taken = personnr_taken;
      this.email_taken = email_taken;

      this.$refs['alreadyTakenPopup'].show();
    },
    async nextPage() {
      
      this.page_data.data = Object.values(this.page_data).reduce((acc, cur) => {
        acc = { ...acc, ...cur.form };
        return acc;
      }, {})
      // Vue3 compatability for Vue.set
      this.page_data = { ...this.page_data };

      if (this.currentPageType == 'details') {
        const ref_name = `ref_page_${this.currentPageType}`;
        const ref = this.$refs[ref_name][0];
        const valid = await ref.validate();
        
        if (!valid) { return; }
      }

      this.continueNextPageAfterCheckIdentification();
    },
    previousPage(e) {
      if (this.previousIndex >= 0) {
        this.renderPage = this.pageArray[this.previousIndex];
        this.currentPage = this.previousIndex;
        this.currentPageType = this.renderPage.type;
        this.calcNextButtonIsOKForCurrentPage();
      }

       window.scrollTo(0, 0);
    },
    // isIdCurrentPage(id) {
    //   return this.renderPage !== null && this.renderPage !== undefined && this.renderPage.id === id;
    // },
    isAllPolicyChecked(isChecked) {
      this.accept_terms = isChecked;
    },
    on_ladok_data(data) {

      if (!this.page_data.data.details) {
        this.page_data.data.details = {};
      }

      this.page_data.data.details.firstname = data.fnamn;
      this.page_data.data.details.lastname = data.enamn;

      // trigger updates
      this.page_data.data = { ...this.page_data.data };
    },
    doSparLookup(personnr) {

      axios
        .post(`/member/spar_personnr`, { personnr })
        .then(res => {
          if (res.status === 200) {
            this.spar = res.data;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.OK'), 'Kunde inte göra SPAR uppslag');
        });
    },
    ageUpdated(age) {
      const parent_page = Object.values(this.page_data).find(item => item.type === 'parents');

      if (!parent_page) {
        return;
      }

      parent_page.skip = age >= parent_page.settings.from_age;

      // Vue3 compatability for Vue.set
      this.page_data = { ...this.page_data };
    },
    membershipUpdated(memberships) {
      if (!this.familyPage) {
        console.error('skip because family page is false');
        return;
      }

      const skip = memberships.find(m => m.is_family) ? false : true;

      this.page_data[this.familyPage.id] = {
        ...this.page_data[this.familyPage.id],
        skip
      };
      // Vue3 compatability for Vue.set
      this.page_data = { ...this.page_data };

    },
    toLoginClicked() {
      if (this.company.company_settings.login_url_register) {
        if ( window.location !== window.parent.location ) {
          window.open(this.company.company_settings.login_url_register)
        }
        else {
          window.open(this.company.company_settings.login_url_register, '_self')
        }
      }
      else {
        window.open('/login/' + this.company_id, '_self')
      }
    },
    to_login_cancel_clicked() {
      if (this.email_taken) {
        this.email_taken = false;
        this.$refs['ref_page_details'][0].clear_email();
      }
    },
    async loadData() {
      this.company_id = this.$route.params.id;
      await this.load_registration(this.company_id);
      await this.load_regions();

      setKeyValue('company_id', this.company_id);

    },
    async load_registration(company_id) {

      try {
        const res = await axios.get(`/company/public/${company_id}`)

        this.company = res.data;
        this.memberships = [];
        this.extras = [];

        if (this.company.company_settings.custom_font_href) {
          const link = document.createElement('link');
          link.href = this.company.company_settings.custom_font_href;
          link.rel = 'stylesheet';

          document.getElementsByTagName('head')[0].appendChild(link);
        }

        set_session_customer_id(res.data.customer_id);

        this.requirements = {
          MEMBER_REQ_PERSONNR: false,
          MEMBER_REQ_SSN: false,
          MEMBER_REQ_AGE: false,
          MEMBER_REQ_SEX: false,
          MEMBER_REQ_ADDRESS: false,
          MEMBER_REQ_FULLNAME: false,
          MEMBER_REQ_EMAIL: false,
          MEMBER_REQ_AVD: false,
          MEMBER_REQ_PHONE: false,
          MEMBER_REQ_EMAILPHONE: false,
          MEMBER_REQ_PASSWORD: false,
          MEMBER_REQ_ORG_NUMBER: false,
          MEMBER_REQ_CNAME: false,
        };

        for (const r of res.data.requirements) {
          if (r.req_id === 9) {
            this.requirements.MEMBER_REQ_PASSWORD = true;
          }
          else {
            this.requirements[r.name] = true;
          }
        }

        this.company.shop_items.filter(item => {
          if (item.is_member_fee && Boolean(item.is_active))
            this.memberships.push(item);
          if (!item.is_member_fee && Boolean(item.is_active)) this.extras.push(item);
        });

        this.accept_terms = false;

        this.registration = res.data.registration;

        if (this.registration.register_css) {
          let file = document.createElement('link');
          file.rel = 'stylesheet';
          file.href = this.registration.register_css;
          file.type = 'text/css';
          document.head.appendChild(file)
        }

        if (this.registration.lang) {
          i18nService.setActiveLanguage(this.registration.lang);
        }

        this.pages = res.data.pages;

        const final_pages = [];

        for (const p of this.pages) {
          if (p.settings === null || p.settings === undefined) {
            final_pages.push(p);
            continue;
          }

          if (p.settings.is_register === null || p.settings.is_register === undefined) {
            final_pages.push(p);
            continue;
          }

          if (p.settings.is_register) {
            final_pages.push(p);
          }
        }

        this.pages = final_pages;

        const company_page = this.pages.find(item => item.type === 'company_select_v2');

        if (!company_page) {

          if (this.company_ids.length === 0 && this.company) {
            this.company_ids = [this.company.company_id];
          }

          // temporary fix to get shop items when we dont have a company select page
          if (this.$refs['ref_page_shop']) {
            this.$refs['ref_page_shop'][0].load_items_manually(this.company_ids);
          }
        }

        this.page_data = this.pages.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});


        this.page_data.data = {};
        // Vue3 compatability for Vue.set

        this.page_data = { ...this.page_data };

        
        console.log(JSON.stringify(this.page_data));

        this.renderPage = this.pages[0];

        this.currentPageType = this.renderPage.type;

        for (const p of this.pages) {
          this.pageValidations[p.type] = false;
        }

        setTimeout(function() {
          messageParent(JSON.stringify({ height: document.body.scrollHeight }));
        }, 300);


        return true;
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PUBLIC.REGISTRATION.UNABLE_GET_WIZARD') );
        this.error_loading = true;
      }

      return false;
    },
    async createAccount() {
      this.disable_create_button = true;

      let data = this.validateData(this.postForm);

      data.area_alloc = {
        company_ids: this.company_ids
      };

      /// if no area alloc page, we add the company id
      if (data.area_alloc.company_ids.length === 0) {
        data.area_alloc.company_ids = [this.company_id];
      }

      // when we have to verify BankID, we need to forward the user to the sign page
      // when its been verified, we then post the registration
      if (this.company.company_settings.enable_verify_bankid) {

        try {
          setKeyValue('post_register_url', this.company.company_settings.post_register_url);
          setKeyValue('form', JSON.stringify(data));
          setKeyValue('personnr', data.personnr.personnr);

          this.$router.push({ name: 'bankid-verify-member' });
          return;
        }
        catch (err) {
          console.error('createAccount register save bankid settings error', err);
        }

      }


      const loader = this.$loading.show();

      data.company_id = getKeyValue('company_id');

      axios
        .post('/member/public', data)
        .then(res => {
          loader && loader.hide();

          if (res.status === 201) {
            messageParent(
              JSON.stringify({
                register: true,
                member_id: res.data.member_id,
                token: res.data.token
              })
            );

            const order = res.data.order;
            const member_id = res.data.member_id;

            if (order) {
              switch (order.expected_action) {
                case 'NOT_DETERMINED': {

                }
                case 'SELECT_PAYMENT': {
                  this.$router.push(`/select-payment/${order.token}/${order.shop_order_id}`);
                  break;
                }
                case 'DINTERO_PAYMENT': {
                  this.$router.push(`/payment/${order.shop_order_id}/${order.dintero_id}/${order.token}`);
                  break;
                }
                case 'STRIPE_PAYMENT': {
                  /// send to stripe payment with register as type
                  this.$router.push(`/stripe-payment/${member_id}?client_secret=${order.stripe_client_secret}&type=register&token=${order.token}`);
                  break;
                }
                case 'NO_PAYMENT': {
                  if (this.company.company_settings.post_register_url) {
                    let url = this.company.company_settings.post_register_url;

                    // add http:// if not present

                    if (!url.match(/^https?:\/\//)) {
                      url = 'http://' + url;
                    }

                    messageParent(JSON.stringify({ redirect: url }));

                    if (!isPlugin()) {
                      window.location.href = url;
                    }
                  } else {
                    this.created = true;
                  }
                  break;
                }
                default: {
                  /// STRIPE_INVOICE_PAYMENT
                  /// INVOICE_PAYMENT
                  /// NOT_DETERMINED
                  /// UNKNOWN
                  this.toastr(this.$t('COMMON.ERROR'), 'Not implemented payment option: ' + order.expected_action);
                  break;
                }
              }
            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), 'No order was created');
            }
          } else if (res.status === 409) {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'E-mail finns redan registrerad');
            this.disable_create_button = false;
          } else {
            this.error = true;
            messageParent(JSON.stringify({ register: false }));
            this.disable_create_button = false;
          }
        })
        .catch(err => {
          loader && loader.hide();

          messageParent(JSON.stringify({ register: false }));

          this.error = true;
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa medlemskonto');

          this.disable_create_button = false;
        });
    },
    validateData(data) {

      // TODO: For now, make sure that everything that the backend requires exists on the object
      // Later, we should make sure that the data is valid and aligned with the backend
      if (!data.area_alloc || !data.area_alloc.company_ids || data.area_alloc.company_ids.length === 0) {
        data.area_alloc = { company_ids: [this.company_id] };
      }

      return data;
    },
    async load_regions() {
      try {
        const res = await axios.get('https://zip.memlist.se/api/v1/regions_communes')

        this.regions = res.data.regions;
        this.regionOptions = [];

        for (var k in this.regions) {
          this.regionOptions.push({ value: k, text: this.regions[k].region });
        }

        this.regionOptions.sort(function(a, b) {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });

      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta regioner');
      }

    },
  }
};
</script>
<style lang="css">

.reg-button {}
.reg-title {}
.reg-help {}
.reg-input {}

</style>